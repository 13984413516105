import React, { useEffect, useRef } from "react";
import { TweenMax } from 'gsap';

import Layout from "../components/layout"
import styles from "./index.module.css"

const IndexPage = () => {
    let heading = useRef(null);
    let subheading = useRef(null);

    useEffect(() => {
        TweenMax.to(heading, 1, {
            opacity:1
        });

        TweenMax.set(subheading, {
            x: -70
        })
        TweenMax.to(subheading, 0.5, {
            opacity: 1,
            x: 0,
            delay: 0.5,
        });
    })

    return (
        <Layout>
            <div className={styles.header}>
                <h2 className={styles.heading} ref={el => {heading = el}}>DAVE HOWSON</h2>
                <h2 className={styles.subheading} ref={el => {subheading = el}}>Web Developer</h2>
            </div>
        </Layout>
    )
}

export default IndexPage
